<template>
    <sm-editable-item
      v-model="form"
      :controllerName="controllerName"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :fields="fields"
      @save="onSave('CallCenterBases')"
      @cancel="onCancel('CallCenterBases')"
    />
  </template>
  
  <script>
  // mixins
  import editableListItemCreate from '@/mixins/editableListItemCreate.js';
  // vuex
  import { mapState, mapActions } from 'vuex';
  // components
  import SmEditableItem from '@/components/views/SmEditableItem.vue';
  
  export default {
    name: 'CallCenterBaseCreate',
  
    components: {
      SmEditableItem,
    },
  
    mixins: [editableListItemCreate],
  
    data() {
      return {
        controllerName: 'CallCenterBases',
        pageHeader: 'Создание базы для колл центра',
        breadcrumbs: [
          {
            text: 'Администрирование',
            route: { name: 'AdministrationMain' },
          },
          {
            text: 'Базы для колцентра',
            route: { name: 'CallCenterBases' },
          },
          {
            text: 'Создание базы для колл центра',
          },
        ],
        initialForm: {
          name: null
        },
        form: null,
      };
    },
  
    computed: {
      ...mapState({
        missedCallTemplatesGroups: (state) => state.common.missedCallTemplatesGroups,
      }),
  
      fields() {
        return [
          {
            form: [
              {
                type: 'text',
                key: 'name',
                label: 'Имя',
              },
            ],
          },
        ];
      },
    },
    
    created() {
      this.getCommonList({ name: 'MissedCallTemplatesGroups' });
    },
  
    methods: {
      ...mapActions({
        createItem: 'editableList/createItem',
        getCommonList: 'common/getCommonList',
      }),
    },
  };
  </script>
  